import React, { FC } from "react"
import { Provider } from "react-redux";
import { useGetLicenceGroupsQuery } from "../Services/VregApi";
import { ApiRequest } from "../Services/ApiRequest";
import Loader from "../../components/loader";
import { store } from "../Search/vregSearchStore";
import { LanguageContext, getLanguage } from "../../components/LanguageContext";

interface Props {
    language: string,
    reportPageUrl: string
}

export const ReportByLicenceGroupsWrapper: FC<Props> = (props: Props) =>
    <Provider store={store}  >
        <LanguageContext.Provider value={getLanguage(props.language)}>
            <React.StrictMode>
                <ReportByLicenceGroups {...props} />
            </React.StrictMode>
        </LanguageContext.Provider>
    </Provider>

const ReportByLicenceGroups: FC<Props> = (props) => {


    const { data, error, isLoading } = useGetLicenceGroupsQuery({ language: props.language } as ApiRequest);

    if (error) {
        return <h2>Det oppstod en feil ved innlasting!</h2>
    }

    if (isLoading) {
        return (
            <Loader isLoading languageCode={props.language} />
        )
    }

    return (
        <ul>
            {data?.map(group =>
                <li key={group.id}
                    className="mb-3 lg:w-8/12 p-normal bg-black-100 text-bluegreen-500 border-l-4 border-bluegreen-500 font-semibold icon icon--arrow__right icon--position__farright">
                    <a href={`${props.reportPageUrl}?group=${group.id}`}>{group.name}</a>
                </li>
            )}
        </ul>
    );
};
