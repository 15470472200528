export const no = {
    cookieBanner:{
        heading: 'Denne nettsiden bruker informasjonskapsler',
        close: 'Lukk varsel om informasjonskapsler',
        open: 'Åpne varsle om informasjonskapsler',
        fallbackText: 'Nødvendige informasjonskapsler (cookies) ivaretar grunnleggende funksjoner som sidenavigasjon og tilgang til sikre områder av nettstedet. Nettstedet er kan ikke fungere optimalt uten disse informasjonskapslene.',
        acceptButton: 'Godta og fortsett',
        readMore: 'Les mer'
    },
    globalsearch: {
        buttons: {
            search: 'Søk',
            goToPage: 'Gå til side %(0)s',
        },
        texts: {
            filtersLabel: 'Avgrens søkeresultat',
            inputLabel: 'Søk i nettstedet',
            inputPlaceholder: 'Søk i Finanstilsynet.no',
            searchFilters: 'Filtrer søkeresultat',
            searchResults: 'Søkeresultat',
            page: 'Side',
            totalHitsText: 'treff',
            of: 'av',
            showing: 'Viser',
            hits: 'Antall treff',
            sorting: 'Sorteringsrekkefølge',
            currentPage: 'Du er på side %(0)s av %(1)s',
        },
    filters: {
            konsesjon: 'Konsesjon',
            regelverk: 'Regelverk',
            tilsyn: 'Tilsyn',
            rapportering: 'Rapportering',
            rundskriv: 'Rundskriv',
            brev: 'Brev',
            nyheter: 'Nyheter',
            pressemeldinger: 'Pressemeldinger',
            filterResults: 'Avgrens søk',
        },
        sorting: {
            sortby: 'Sortert etter',
            relevance: 'Relevans',
            date: 'Dato',
        },
        nohits: {
            header: 'Søket ga dessverre ingen treff',
            searchtips: 'Søketips',
            spelling: 'Sjekk om søkestrengen er stavet korrekt',
            registers: 'Søker du etter et foretak, prøv å søke i våre registre',
            formulation: 'Prøv andre formuleringer som kan bety det samme',
            abbreviation: 'Unngå forkortelser og nummer/dato/tall i søkestrengen',
            menu: 'Eventuelt se om du kan finne det du leter etter via menyen øverst på siden',
        },        
    },
    newsarchive: {
        headers: {
            filter: 'Filtrer visninga',
        },
        buttons: {
            search: 'Søk',
            ok: 'Ok',
            shareSearch: 'del',
            chooseYear: 'Alle år',
            readMore: 'Les mer',
            loadMore: 'Last flere',
            resetFilter: 'Fjern valgte',
            closeFilter: 'Lukk filter'
        },
        texts: {
            totalHitsText: 'treff',
            filterResultsText: 'Avgrens visningen',
            yearFilterDescription: 'Velg år',
            metadataFilterDescription: 'Velg filter',
            loadMoreDescription: 'Last flere ikon',
            inputPlaceholder: 'Søk i nyhetsarkivet',
            searchResult: 'Søkeresultat',
            totalHitsTemplate: 'Viser %(0)s - %(1)s av %(2)s treff',
            noHits: 'Ingen treff',
            selectedNewsTypes: 'Du har valgt følgende nyhetstyper',
            filteredForPeriod: 'Du ser på perioden %(0)s',
            filteredForPeriodAndOlder: 'Du ser på perioden %(0)s og eldre'
        },
        select: {
            CircularLetterPage: 'Alle rundskriv',
            andOlderText: ' og eldre',
            InspectionReportPage: 'Vis kun vedtak'
        },
        search: {
            placeholder: 'Søk i nyhetsarkivet',
            sortBy: 'Sorter etter:',
            sortByDate: 'Dato',
            sortByAlphabet: 'Alfabet'
        },
    },
    licenseRegistry: {
        headers: {
            filter: 'Vis/skjul filter',
            typefilter: 'Velg konsesjonstype',
            countryFilter: 'Velg land',
            countyfilter: 'Velg fylke',
            classfilter: 'Velg tjeneste/klasse',
            virksomhetstypeFilters: 'Inkluder virksomheten til',
        },
        labels: {
            licenses: 'Konsesjoner',
            showing: 'Viser',
            of: 'av',
            allLicenseTypes: 'Alle konsesjonstyper',
            allClasses: 'Alle tjenester/klasser',
            allCountries: 'Alle land',
            allCounties: 'Alle fylker',
            foreignCountries: 'Utlandet',
            konsesjonshavereFilter: 'Konsesjonshavere',
            agentFilter: 'Agenter',
            filialFilter: 'Filialer',
        },
        search: {
            placeholder: 'Skriv inn navn eller organisasjonsnummer',
            resetFilters: 'Nullstill filter',
            searching: 'Søker...',
        },
        reports: {
            headers: {
                customReport: 'Egendefinert rapport',
                predefinedReports: 'Forhåndsdefinerte rapporter',
            },
            reportType: {
                norway: 'Virksomhet i Norge med konsesjon fra Finanstilsynet',
                gov: 'Grensekryssende virksomhet',
            },
            from: 'Til Norge fra',
            to: 'Fra Norge til',
            generateReport: 'Lag rapport',
            downloadPredefinedReport: 'Last ned som regneark',
            isGeneratingReport: 'Vennligst vent',
            reportGenerationFailed: 'Noe gikk galt, vennligst prøv igjen.',
            resetFilters: 'Tilbakestill filter',
            infotext: {
                domesticReport:
                    'Dette valget inkluderer foretak som har forretningsadresse i Norge, og som opererer under konsesjon gitt av Finanstilsynet. Uttrekket inkluderer altså ikke norskregistrerte utenlandske foretak (NUF) siden de opererer på utenlandsk konsesjon.',
                govReport:
                    'Dette valget inkluderer de foretak som har meldt grensekryssende virksomhet ut av eller inn til Norge, med etablering (f.eks. norskregistrert utenlandsk foretak (NUF)) og uten etablering. Uttrekket inkluderer altså ikke norske foretak som kun opererer i Norge.',
                includeKonsesjonshavere:
                    'Dette valget inkluderer kun foretak eller personer som opererer under konsesjon gitt av tilsynsmyndigheten i sitt hjemland. Foretak som er etablert i Norge, men som opererer på vegne av foretak i andre land, er ikke inkludert. F.eks. vil Nordea Bank ABP i Norge ikke inkluderes da Nordea opererer som en filial på vegne av sitt hovedkontor Nordea Bank ABP i Finland, og det er sistnevnte som er konsesjonshaver.',
                includeFilialer:
                    'Dette valget inkluderer kun foretak som opererer som filialer/avdelingskontor på vegne av deres hovedkontor. Dette kan være både norske filialer/avdelingskontor av norske foretak, utenlandske filialer av norske foretak, samt norske filialer av utenlandske foretak. Virksomheten drives på bakgrunn av konsesjon tildelt hovedkontoret (konsesjonshaver).',
                includeAgenter:
                    'Dette valget inkluderer kun tilknyttede agenter som opererer på vegne av andre foretak. Dette kan være både agenter i Norge tilknyttet utenlandske foretak, agenter i utlandet tilknyttet norske foretak, samt agenter i Norge tilknyttet norske foretak. Agentens virksomhet drives på bakgrunn av konsesjon tildelt foretaket det opererer på vegne av (konsesjonshaver). Agentene har mao. ikke konsesjon gitt av tilsynsmyndighet.',
            },
            validation: {
                missingKonsesjontypeOrFylke: 'Enten konsesjonstype eller fylke må være valgt',
                missingKonsesjontypeOrLand: 'Enten konsesjonstype eller land må være valgt',
                missingVirksomhetstype: 'Minimum én av virksomhetstypene må være valgt',
            },
            predefinedReports: {
                Nominee: {
                    heading: 'Forvalter (nominee) i norske eierregistre',
                    description:
                        'Rapporten inneholder oversikt over norske og utenlandske foretak med godkjenning fra Finanstilsynet til å opptre som Forvalter (nominee).',
                },
                DomesticActivityBank: {
                    heading:
                        'Banker, kreditt-, betalings-, finansierings- og e-pengeforetak  i Norge',
                    description:
                        'Rapporten inneholder alle banker, kredittforetak, finansieringsforetak, og e-pengeforetak i Norge uavhengig av om virksomheten er under konsesjon gitt av Finanstilsynet eller utenlandsk tilsynsmyndighet. Dvs. at både norskregistrerte utenlandske foretak (NUF) og norske virksomheter under tilsyn av Finanstilsynet er inkludert.',
                },
                DomesticActivityInsurance: {
                    heading: 'Skade- og livsforsikringsforetak og pensjonskasser i Norge',
                    description:
                        'Rapporten inneholder alle skadeforsikringsforetak, livsforsikringsforetak, private pensjonskasser, kommunale pensjonskasser og innskuddspensjonsforetak i Norge uavhengig av om virksomheten er under konsesjon gitt av Finanstilsynet eller utenlandsk tilsynsmyndighet. Dvs. at både norskregistrerte utenlandske foretak (NUF) og norske virksomheter under tilsyn av Finanstilsynet er inkludert.',
                },
            },
        },
    },
    supervision: {
        WelcomeTextWithCheckedItems: {
            zero: 'Ikke begrenset',
            one:
                'Du har oppgitt %(count)s fagområde. Du kan endre de valgte fagområdene ved å trykke på knappen under.',
            other:
                'Du har oppgitt %(count)s fagområder. Du kan endre de valgte fagområdene ved å trykke på knappen under.',
        },
        WelcomeText:
            'Du kan velge å filtrere på fagområder dersom du ønsker. Klikk på knappen under for å komme i gang.',
        buttons: {
            startFiltering: 'Velg fagområder',
            changeFiltering: 'Endre fagområder',
            saveSelection: 'Lukk fagområdevelger',
            reset: 'Nullstill valg',
        },
        'mobile-header': 'Filtrer på fagområde',
        'mobile-description': 'Velg fagområde',
        'warning-bar-limited': ' - Du ser nå en avgrenset visning - ',
        'warning-bar-reset': 'Klikk for å nullstille valg',
        'all-categories': 'Alle fagområder',
        'warning-bar-count': {
            zero: 'Ikke begrenset',
            one: 'Ett fagområde er valgt',
            other: '%(count)s fagområder er valgt',
        },
        headers: {
            InitialHeader: 'Velg fagområder',
        },
    },
    subjectSelector: {
        selectedSubjects: 'Du har valgt følgende fagområder:',
        subjectAreaTitle: 'Vis valg for',
        dropdownTextFallback : 'Velg område',
        buttons: {
            open: 'Åpne områdevelger',
            close: 'Lukk',
            closeSelector: 'Lukk områdevelger',
            reset: 'Fjern valgte',
            selectAll: 'Velg alle / fjern valg'
        }
    },
    feedback: {
        headers: {
            mainHeader: 'Fant du det du lette etter?'            
        },
        texts: {
            contactUs: 'Her svarer vi ikke, men kontakt oss på e-post eller telefon om du trenger hjelp.',
            errorTryAgain: 'Ops, prøv igjen...',
            feedbackPlaceholder: 'Skriv inn din tilbakemelding...',
            feedbackTextAreaLabel: 'Skriv inn en tilbakemelding',
            label: 'Gi tilbakemelding',
            responseText: 'Takk for din tilbakemelding!',
        },
        buttons: {
            feedback: 'Gi oss tilbakemelding',
            submit: 'Send tilbakemelding',
        },
    },
    newsletter: {
        heading: 'Nyhetsvarsel',
        emailRegister:
            'Skriv inn e-postadressen din for å motta oppdateringer på de siste nyhetene fra Finanstilsynet',
        receipt: 'Sjekk din e-post for å fullføre registeringen til nyhetsbrevet',
        registrationComplete: 'Registeringen er vellykket.',
        invalidEmail: 'Ugyldig e-postadresse',
        submit: 'Registrer',
        caption: 'E-post',
        toggleSelection: 'Velg alle / slett valgte'
    },
    prospectus: {
        choosedocumenttype: 'Vel dokumenttype',
        chooseprospectustype: 'Vel kategori',
        alltypes: 'Alle dokumenttypar'
    },
    publications: {
        shortcuts: 'Snarvegar',
        sortAlphabetically: 'Vis alfabetisk',
        resetSortAlphabetically: 'Fjern alfabetisk'
    },
    search: {
        searchFieldLabel: 'søkefelt',
        button: 'søkeknapp',
        buttons: {
            next: 'Neste',
            prev: 'Forrige'
        }
    },
    sortSelect: {
        date: 'Sortert etter dato',
        alphabetically: 'Sortert alfabetisk',
        sorting: 'Sortering',
    },
    usersurvey: {
        header: 'Vil du bidra til å forbedre virksomhetsregisteret?',
        clickToReadMore: 'Klikk her for å lese mer',
        weNeedYourFeedback: 'Vi ønsker å forbedre nettsiden vår og trenger dine tilbakemeldinger.',
        buttonText: 'Start undersøkelsen',

    },
    infoAbout: 'Vis informasjon om',
    themesOverview: {
        headers: {
            filter: 'Filtrer visningen',
        },
    },
    menu: {
        buttons: {
            expand: 'Meny',
            close: 'Lukk meny'
        },
        labels: {
            subscribe: 'Abonner på nyhetsvarsel',
            search: 'Søk i nettstedet',
            goToSearch: 'Gå til søkesiden'
        },
        language: {
            otherLanguage: 'English',
            showInOtherLanguage: 'Show this page in English',
            otherHomePage: 'English home page',
            doesNotExistInOtherLanguage: 'This page does not exist in English'
        }
    },
    toc: 'Denne siden inneholder:',
    vreg: {
        buttons: {
            'print': 'Skriv ut',
            'download': 'Last ned',
            'close': 'Lukk',
            'expand': 'Ekspander',
            selectAll: 'Velg alle / fjern valg',
            resetCounties: 'Nullstill'
        },
        entityTypes:{
            company: 'Foretak',
            person: 'Person',
            branch: 'Filial',
            all: 'Alle'
        },
        filters: {
            licenceType: 'Konsesjonstype',
            registrationType: 'Registreringstype',
            close: 'Lukk filter',
            filter: 'Filtrer søket',
            country: {
                title: 'Land',
                all: 'Alle land',
                norway: 'Norge',
                other: 'Andre land'
            },
            county: {
                title: 'Geografisk område i Norge',
            },
            providerTypes:{
                title: 'Inkluder virksomheten til'
            }
        },
        search: {
            noHits: 'Søket ditt ga ingen treff',
            legend: 'Søkefelt for enhetssøk',
            placeholder: 'Skriv inn navn eller organisasjonsnummer',
            searchButtonText: 'Søk',
            showingHitsXofY: 'Side %(0)s av %(1)s for søkeuttrykket "%(2)s". Totalt %(3)s treff.',
            error: 'Feil under innlasting',
            fromVreg: 'Virksomhetsregisteret'
        },
        'accreditedAuditors': '%(0)s statsautoriserte revisorer i selskapet',
        'auditorFor': 'Revisor i selskap',
        'auditorNumber': 'Revisorregisternummer',
        'board': 'Ledelse/styre',
        'businessAddress': 'Adresse',
        'currentAndTotalLicences': '%(0)s av %(1)s konsesjoner',
        'hasSecurity': 'Har sikkerhet',
        'leiCode': 'LEI-nummer',
        'links': 'Lenker',        
        'licences': 'konsesjoner',
        licenceOwner: 'Konsesjonshaver',
        'networkAndAffiliates': 'Selskapets deltakelse i samarbeid',
        'networkAndAffiliatesDescription': 'Informasjon om selskapets deltagelse i samarbeid finnes på oppgitt nettsted eller kan fås ved henvendelse til oppgitt e-postadresse.',
        'orgnumber': 'Organisasjonsnummer',
        'postalAddress': 'Postadresse',
        'registrationDate': 'Registreringsdato',
        'showMoreInformation': 'Vis mer informasjon',
        'supervisoryAuthority': 'Tilsynsmyndighet',
        'servicesAndClasses': 'Tjenester',
        'typeOfAuditor': 'Type revisor',
        bordercrossingActivity: {
            'title': 'Grensekryssende virksomhet',
            'toCountryWithoutEstablishment': 'Til %(0)s uten etablering',
            'toCountryViaBranch': 'Til %(0)s gjennom filialetablering',
            'toCountryViaAgent': 'Til %(0)s gjennom tilknyttet agent',
            'multipleCountries': 'flere land',
            'canOperateFrom': 'Foretaket kan drive grensekryssende virksomhet fra %(0)s',
            'viaThisBranch': 'Til %(0)s gjennom denne filialen, med følgende tjenester',
            'toCountriesViaThisBranchWithoutEstablishment': 'Til flere land uten etablering via denne filialen i %(0)s, med følgende tjenester',
            'toCountriesViaBranchWithoutEstablishment': 'Til flere land uten etablering via filialer etablert i flere land'
        },
        agent: {
            'isAgentFor': 'Er agent for',
            'relatedToLicence': 'I tilknytning til konsesjonen: %(0)s',
            'registeredDate': 'Registreringsdato som agent for',
            'supervisoryAuthorityFor': 'Tilsynsmyndighet for %(0)s'
        },
        agents:{
            'heading': 'Agenter i %(0)s',
            'showList': 'Vis alle %(0)s agenter',
            'showListDescription': 'Hvis du slår på bryteren (toggle), vil du se hele listen over agenter.',
            'showAllInList': 'Vis hele agentlisten'
        },
        branch:{            
            'isBranchOf': 'Er filial av',
            'relatedToLicence': 'I tilknytning til konsesjonen: %(0)s',
            'registeredDate': 'Registreringsdato for filialen',
            'supervisoryAuthorityFor': 'Tilsynsmyndighet for %(0)s',
            'heading': 'Filialer i %(0)s',
            'totalInCountry': 'Totalt %(0)s filialer i %(1)s',
            'showList': 'Vis listen over filialer',
            'showListDescription': 'Hvis du slår på bryteren (toggle), vil du se hele listen over filialer.',
            'showAllInList': 'Vis hele filallisten'
        },
        list: {
            'showList': 'Vis listen (%(0)s elementer)',
            'showListDescription':'Hvis du slår på bryteren (toggle), vil du se hele listen.',
            'showAllInList': 'Vis hele listen'
        },
        professionalDirector: {
            'heading': 'Fagansvarlig i',
        },
        'remarks': 'Merknader',
        reports:{
            businessCount: 'Antall virksomheter',
            download: 'Last ned Excel av rapporten',
            isLoading: 'Virksomhetsregisteret laster...',
            errorLoading: 'Det oppstod en feil under innlasting',
            licencedNorwegianBusiness:'Virksomhet i Norge med konsesjon fra Finanstilsynet',
            crossBorderBusiness: 'Grensekryssende virksomheter',
            nominee: {
                hasOtherFinancialInstruments: 'Har andre verdipapirer',
                hasStocks: 'Allmennaksjeloven §4-10',
                hasShares: 'Verdipapirfondforskriften §13-2'
            },
            otherAreas: 'Annet virksomhetsområde og konsesjonstype',
            tooltips:{
                show:'Vis informasjon',
                licencedNorwegianBusiness: 'Dette valget inkluderer foretak som har forretningsadresse i Norge, og som opererer under konsesjon gitt av Finanstilsynet. Uttrekket inkluderer altså ikke norskregistrerte utenlandske foretak (NUF) siden de opererer på utenlandsk konsesjon.',
                crossBorderBusiness: 'Grensekryssende virksomhet karakteriseres av virksomhet som tilbys på tvers av landegrensene. Virksomheten kan enten drives som grensekryssende virksomhet fra hjemstaten eller gjennom etablering av filial i vertsstaten, hvor sistnevnte innebærer en mer varig etablering ved egne ansatte.',
                selectServicesToImproveResult: 'Listen under viser virksomheter pr. tjeneste. Hvis man ikke velger en spesifikk tjeneste kan derfor samme virksomhet være listet opp flere ganger.',
                selectServicesShort: 'Velg tjeneste for å avgrense opplistingen under.'
            },
            crossBorder: 'Grensekryss',
            crossBorderSettings:{
                all: 'Alle',
                NorwayOutbound: 'Fra Norge',
                NorwayInbound: 'Til Norge',
                Outbound: 'Til Land',
                Inbound: 'Fra Land',
                select: 'Velg land'
            },
            showingHitsXofY: 'Viser side %(0)s av %(1)s. Totalt %(2)s treff.',
            service: 'Tjenester',
            thirdcountryauditor: 'Tredjelandsrevisor'
        },
        serviceProvider:{
            name: 'Type tjenesteyter',
            types: {
                Agent: 'Agent',
                Branch: 'Filial',
                Licensed: 'Konsesjonshaver',
            }
        }
       },
    common:{
        'yes': 'Ja',
        open: 'Åpne',
        close: 'Lukk',
        show: 'Vis',
        hide: 'Skjul',
        goTo: 'Gå til',
        loading: 'Laster',
        comingSoon: 'Kommer snart'
    },
    components: {
        inspectionReportYearListing: {
            decisionsOnly: 'Vis kun vedtak'
        }
    }

};
