export const en = {
    cookieBanner:{
        heading: 'This web site uses cookies',
        close: 'Close cookie warning banner',
        open: 'Open cookie warning banner',
        fallbackText: 'Required cookies support basic functions such as navigation and access to secure areas of the web site. The web site cannot function optimally without these cookies.',
        acceptButton: 'Accept and continue',
        readMore: 'Read more'
    },
    globalsearch: {
        buttons: {
            search: 'Search',
            goToPage: 'Go to page %(0)s',
        },
        texts: {
            filtersLabel: 'Filter search results',
            inputLabel: 'Search this web site',
            inputPlaceholder: 'Search Finanstilsynet\'s website',
            searchFilters: 'Filter search results',
            searchResults: 'Search result',
            page: 'Page',
            totalHitsText: 'hits',
            of: 'of',
            showing: 'Showing',
            hits: 'Number of hits',
            sorting: 'Sort order',
            currentPage: 'You are on page %(0)s of %(1)s',
        },
        filters: {
            konsesjon: 'Licensing',
            regelverk: 'Laws and regulations',
            tilsyn: 'Supervision',
            rapportering: 'Reporting',
            rundskriv: 'Circular',
            brev: 'Letter',
            nyheter: 'News',
            pressemeldinger: 'Press release',
            filterResults: 'Filter results',
        },
        sorting: {
            sortby: 'Sort by',
            relevance: 'Relevance',
            date: 'Date',
        },
        nohits: {
            header: 'The search returned no results',
            searchtips: 'Search tips',
            spelling: 'Check that the search string is spelled correctly',
            registers: 'If you are looking for a company, try searching our registries',
            formulation: 'Try other formulations that may mean the same',
            abbreviation: 'Avoid abbreviations, numbers and dates in the search string',
            menu:
                'Or see if you can find what you are looking for through the menu at the top of the page',
        },
    },
    newsarchive: {
        headers: {
            filter: 'Filter',
        },
        buttons: {
            search: 'Search',
            ok: 'ok',
            shareSearch: 'share',
            chooseYear: 'All years',
            readMore: 'More',
            loadMore: 'Load more',
            resetFilter: 'Reset filter',
            closeFilter: 'Close filter'
        },
        texts: {
            totalHitsText: 'hits',
            filterResultsText: 'Filter results',
            yearFilterDescription: 'Choose year',
            metadataFilterDescription: 'Choose filter',
            loadMoreDescription: 'Load more icon',
            inputPlaceholder: 'Search the news archive',
            searchResult: 'Search result',
            totalHitsTemplate: 'Showing %(0)s - %(1)s of %(2)s hits',
            noHits: 'No hits',
            selectedNewsTypes: 'You\'ve selected these news types',
            filteredForPeriod: 'For the period %(0)s',
            filteredForPeriodAndOlder: 'For the period %(0)s and older'
        },
        select: {
            CircularLetterPage: 'All circular letters',
            andOlderText: ' and older',
        },
        search: {
            placeholder: 'Search the news archive',
            sortBy: 'Sort by:',
            sortByDate: 'Date',
            sortByAlphabet: 'Alphabet'
        },
    },
    licenseRegistry: {
        headers: {
            filter: 'Show/hide filters',
            typefilter: 'Choose licence type',
            countryFilter: 'Choose country',
            countyfilter: 'Choose county',
            classfilter: 'Choose service/class',
            virksomhetstypeFilters: 'Include operations for',
        },
        labels: {
            licenses: 'Licences',
            showing: 'Showing',
            of: 'of',
            allLicenseTypes: 'All licence types',
            allClasses: 'All services/classes',
            allCountries: 'All countries',
            allCounties: 'All counties',
            foreignCountries: 'Foreign countries',
            konsesjonshavereFilter: 'Licensed entities',
            agentFilter: 'Agents',
            filialFilter: 'Branch offices',
        },
        search: {
            placeholder: 'Enter a name or organisation number',
            resetFilters: 'Reset filters',
            searching: 'Searching...',
        },
        reports: {
            headers: {
                customReport: 'Custom report',
                predefinedReports: 'Predefined reports',
            },
            reportType: {
                norway: 'Operations in Norway with licence from Finanstilsynet',
                gov: 'Cross-border operations',
            },
            from: 'To Norway from',
            to: 'From Norway to',
            generateReport: 'Generate report',
            downloadPredefinedReport: 'Download as spreadsheet',
            isGeneratingReport: 'Please wait',
            reportGenerationFailed: 'Something went wrong, please try again.',
            resetFilters: 'Reset filters',
            infotext: {
                domesticReport:
                    'This selection includes those companies whose registered head office is situated in Norway and who operate under a licence issued by the Financial Supervisor Authority of Norway. The selection does not include Norwegian-registered foreign companies (NUF), as they operate under a foreign licence.',
                govReport:
                    'This selection includes companies operating on a cross-border basis to or from Norway, with establishment (e.g. Norwegian-registered foreign company (NUF)) and without establishment. The selection does not include Norwegian companies operating solely within Norwegian territory.',
                includeKonsesjonshavere:
                    "This selection includes only natural or legal persons operating under a licence issued by the competent authority of the country in which their head office is situated. The selection does not include companies established in Norway and which is operating on behalf of their head office situated in another country. E.g. the operations of Nordea Bank ABP in Norway is not included as it is a branch operating on behalf of Nordea Bank ABP's head office in Finland, the latter of which is the licensed emtotu.",
                includeFilialer:
                    "This selection includes only branch offices operating on behalf of their head office. This may include Norwegian branches operating on behalf of Norwegian companies, foreign branches operating on behalf of Norwegian companies or Norwegian branches operating on behalf of foreign companies. Branch operations are performed based on the licence issued to the branch's head office (the licensed entity).",
                includeAgenter:
                    'This selection includes only tied agents operating on behalf of another company. This may include Norwegian tied agents operating on behalf of foreign companies, foreign tied agents operating on behalf of Norwegian companies and Norwegian tied agents operating on behalf of Norwegian companies. The operations of tied agents are performed based on the licence issued to company (the licensed entity) on which behalf the agents is operating.',
            },
            validation: {
                missingKonsesjontypeOrFylke: 'Either licence type or county must be selected',
                missingKonsesjontypeOrLand: 'Either licence type or country must be selected',
                missingVirksomhetstype: 'At least one type of operations must be selected',
            },
            predefinedReports: {
                Nominee: {
                    heading: 'Nominee in Norwegian securities registers',
                    description:
                        'The report contains an overview of Norwegian and foreign companies approved by Finanstilsynet to act as a Nominee.',
                },
                DomesticActivityBank: {
                    heading:
                        'Banks, credit-, payment-, finance-, and e-money institutions in Norway',
                    description:
                        'The report includes all banks, credit institutions, payment institutions, finance companies and e-money institutions in Norway, regardless of whether the business operates under a license by Finanstilsynet or a foreign authority. I.e. both Norwegian-registered foreign companies (NUF) and Norwegian companies operating under the supervision of Finanstilsynet are included.',
                },
                DomesticActivityInsurance: {
                    heading: 'Life- and non-life insurance companies and pension funds in Norway',
                    description:
                        'The report includes all life insurance companies, non-life insurance companies, private pension funds and municipal pension funds in Norway, regardless of whether the business operates under a license by Finanstilsynet or a foreign authority. I.e. both Norwegian-registered foreign companies (NUF) and Norwegian companies operating under the supervision of Finanstilsynet are included.',
                },
            },
        },
    },
    supervision: {
        WelcomeTextWithCheckedItems: {
            zero: 'Not limited',
            one:
                'You have seleced %(count)s category. You can make a new selection by clicking the button below.',
            other:
                'You have seleced %(count)s categories. You can make a new selection by clicking the button below.',
        },
        WelcomeText:
            'You can choose to filter on supervision categories. Click on the button below to start filtering.',
        buttons: {
            startFiltering: 'Select categories',
            changeFiltering: 'Change categories',
            saveSelection: 'Close supervision category selector',
            reset: 'Reset selection',
        },
        'mobile-header': 'Filter by category',
        'mobile-description': 'Choose category',
        'warning-bar-limited': ' - You are now looking at a filtered view - ',
        'warning-bar-reset': 'Click here to reset selection',
        'all-categories': 'All categories',
        'warning-bar-count': {
            zero: 'Not limited',
            one: 'One supervision category is selected',
            other: '%(count)s categories are selected',
        },
        headers: {
            InitialHeader: 'Filter by category',
        },
    },
    subjectSelector:{
        selectedSubjects: 'You have selected the following subjects:',
        subjectAreaTitle: 'Show options for',
        dropdownTextFallback: 'Choose subject',
        buttons: {
            open: 'Open subject selector',
            close: 'Close',
            closeSelector: 'Close subject selector',
            reset: 'Remove selections',
            selectAll: 'Select all / none',
        }
    },
    feedback: {
        headers: {
            mainHeader: 'Did you find what you were looking for?',            
        },
        texts: {
            contactUs: 'We do not reply here, but contact us by e-mail or telephone if you require assistance.',
            errorTryAgain: 'Oops, please try again...',
            feedbackPlaceholder: 'Please enter your feedback... ',
            feedbackTextAreaLabel: 'Please write us a feedback message',
            label: 'Leave feedback',
            responseText: 'Thank you for your response!'
        },
        buttons: {
            feedback: 'Leave feedback',
            submit: 'Send feedback',
        },
    },
    newsletter: {
        heading: 'Newsletter',
        emailRegister:
            'Enter your email to recive updates from the Financal supervisiory of norway',
        receipt: 'Please check your email for further instructions',
        registrationComplete: 'Registration successful.',
        invalidEmail: 'Invalid email',
        submit: 'Register',
        caption: 'Email',
        toggleSelection: 'Toggle selection'
    },
    prospectus: {
        choosedocumenttype: 'Choose document type',
        chooseprospectustype: 'Choose category',
        alltypes: 'All document types'
    },
    publications: {
        shortcuts: 'Shortcuts',
        sortAlphabetically: 'Sort alphabetically',
        resetSortAlphabetically: 'Clear alphabetical sort'
    },
    search: {
        searchFieldLabel: 'search field',
        button: 'search button',
        buttons: {
            next: 'Next',
            prev: 'Previous'
        }
    },
    sortSelect: {
        date: 'Sorted by date',
        alphabetically: 'Sorted alphabetically',
        sorting: 'Sorting',
    },
    
    infoAbout: 'Show information about',
    themesOverview: {
        headers: {
            filter: 'Filter',
        },
    },
    menu: {
        buttons: {
            expand: 'Menu',
            close: 'Close menu'
        },
        labels: {
            subscribe: 'Subscribe to news',
            search: 'Search',
            goToSearch: 'Go to the search page'
        },
        language: {
            otherLanguage: 'Norwegian',
            showInOtherLanguage: 'Show this page in Norwegian',
            otherHomePage: 'Norwegian home page',
            doesNotExistInOtherLanguage: 'This page does not exist in Norwegian'
        }
    },
    toc: 'This page contains:',
    vreg: {
        buttons: {
            'print': 'Print',
            'download': 'Download',
            'close': 'Close',
            'expand': 'Expand',
            selectAll: 'Select all / reset selection',            
            resetCounties: 'Reset'
        },
        entityTypes:{
            company: 'Company',
            person: 'Person',
            branch: 'Branch',
            all: 'All'
        },
        filters: {
            licenceType: 'Licence type',
            registrationType: 'Registration type',
            filter: 'Filter results',
            close: 'Close filter',      
            country: {
                title: 'Country',
                all: 'All countries',
                norway: 'Norway',
                other: 'Other countries'
            },
            county: {
                title: 'Geographical area in Norway',
            },
            providerTypes:{
                title: 'Include license provider types'
            }
        },
        search: {
            noHits: 'Your search returned no results',                  
            legend: 'Search field for registry',    
            placeholder: 'Enter name or organization number',
            searchButtonText: 'Search',
            showingHitsXofY: 'Page %(0)s of %(1)s for search "%(2)s". %(3)s hits.',
            error: 'An error occurred.',
            fromVreg: 'Finanstilsynet\'s registry'
        },
        'accreditedAuditors': '%(0)s state authorised auditors in the firm',
        'auditorFor': 'Auditor for',
        'auditorNumber': 'Registration number',
        'board': 'Management/Board',
        'businessAddress': 'Address',
        'currentAndTotalLicences': '%(0)s of %(1)s licences',
        'hasSecurity': 'Has security',
        'leiCode': 'Lei code',
        'links': 'Links',
        'licences': 'licences',
        licenceOwner: 'Licence owner',
        'networkAndAffiliates': 'Network and affiliates',
        'networkAndAffiliatesDescription': 'Information about the network is available either at the given web site or by e-mail request.',
        'orgnumber': 'Organisation number',
        'postalAddress': 'Postal address',
        'registrationDate': 'Registration date',
        'supervisoryAuthority': 'Supervisory authority',
        'servicesAndClasses': 'Services',
        'showMoreInformation': 'Show more information',
        'typeOfAuditor': 'Type of auditor',
        bordercrossingActivity: {
            'title': 'Cross-border services',
            'toCountryWithoutEstablishment': 'To %(0)s without physical establishment, for the following services',
            'toCountryViaBranch': 'To %(0)s through a branch establishment',
            'toCountryViaAgent': 'To %(0)s through a tied agent',
            'multipleCountries': 'multiple countries',
            'canOperateFrom': 'The entity may provide cross-border services from %(0)s',
            'viaThisBranch': 'To %(0)s through this branch establishment, for the following services',
            'toCountriesViaThisBranchWithoutEstablishment': 'To multiple countries through this branch establishment in %(0)s, for the following services',
            'toCountriesViaBranchWithoutEstablishment': 'To multiple countries without physical establishment, through branches in multiple countries'
        },
        agent: {
            'isAgentFor': 'Is agent for',
            'relatedToLicence': 'Related to licence: %(0)s',
            'registeredDate': 'Registration date as agent',
            'supervisoryAuthorityFor': 'Supervisory authority for %(0)s',
        },
        agents:{
            'heading': 'Agents in %(0)s',
            'showList': 'Show all %(0)s agents',
            'showListDescription': 'If you toggle the switch, the entire list will be shown.',
            'showAllInList': 'Show the entire list'
        },
        branch:{            
            'isBranchOf': 'Is a branch of',
            'relatedToLicence': 'Related to the licence: %(0)s',
            'registeredDate': 'Registration date as branch',
            'supervisoryAuthorityFor': 'Supervisory authority for %(0)s',
            'heading': 'Branches in %(0)s',
            'totalInCountry': 'Total %(0)s branch establishments in %(1)s',
            'showList': 'Show the list of branches',
            'showListDescription': 'If you toggle the switch, the entire list will be shown.',
            'showAllInList': 'Show the entire list'

        },
        list: {
            'showList': 'Show all (%(0)s elements)',
            'showListDescription':'If you toggle the switch, the entire list will be shown.',
            'showAllInList': 'Show the entire list'
        },
        professionalDirector: {
            'heading': 'Professional director for',
        },
        'remarks': 'Remarks',
        reports:{
            businessCount: 'Number of businesses',
            download: 'Download report as Excel',
            licencedNorwegianBusiness:'Operations in Norway with licence from Finanstilsynet',
            crossBorderBusiness: 'Cross-border operations',
            tooltips:{
                show:'Show information',
                licencedNorwegianBusiness: 'This choice includes companies whose registered office is in Norway and operate under a licence granted by the Financial Supervisory Authority of Norway. The exclusion does not include Norwegian-registered foreign companies (NUFs) since they operate on a foreign licence.',
                crossBorderBusiness: 'Cross-border activities can be conducted either from the home state or through the establishment of a branch in the host state. The latter alternative is a more permanent solution, using own employees.',
                selectServicesToImproveResult: 'The list shows companies and individuals under Finanstilsynet’s supervision per licence type/service.',
                selectServicesShort: 'Select a licence type/service to narrow down choices in the list below.'
            },
            nominee: {
                hasStocks: 'The Public Limited Liability Companies Act section 4-10',
                hasShares: 'The Securities Funds Regulations section 13-2'
            },
            otherAreas: 'Other supervision areas and licence types',
            crossBorder: 'Cross-border',
            crossBorderSettings:{
                all: 'All',                
                NorwayOutbound: 'From Norway',
                NorwayInbound: 'To Norway',
                Outbound: 'To country',
                Inbound: 'From country',
                select: 'Select country'
            },
            showingHitsXofY: 'Showing page %(0)s of %(1)s. %(2)s hits total.',
            service:'Services',
            thirdcountryauditor: 'Third country auditor'
        },
        serviceProvider:{
            name: 'Service provider type',
            types: {
                Agent: 'Agent',
                Branch: 'Branch',
                Licensed: 'Licensed',
            }
        }
    },
    common: {
        'yes': 'Yes',
        open: 'Open',
        close: 'Close',
        show: 'Show',
        hide: 'Hide',
        goTo: 'Go to',
        loading: 'Loading',
        comingSoon: 'Coming soon'
    },
    components: {
        inspectionReportYearListing: {
            decisionsOnly: 'Decisions only'
        }
    }
};
