import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { mapToUrlParams, replaceUrlParams } from '../../util/urlHelpers';
import { window } from '../../util/ssrBase';

const queryParams = new URLSearchParams(window.location.search);

function getUrlParams(state: InvestorWarningsSearchState) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newQueryString = mapToUrlParams((addIfNotNull: any) => {
        addIfNotNull('query', state.query);
        addIfNotNull('language', state.language);
        addIfNotNull('page', state.pageNumber);
        state.activeFilters.map((filter) => addIfNotNull('types', filter));
        addIfNotNull('from', state.dateFilter.fromDate);
        addIfNotNull('to', state.dateFilter.toDate);
        addIfNotNull('sortAlphabetically', state.sortAlphabetically.toString());
    });

    state.queryString = newQueryString;
    return newQueryString;
}

function setQuerystring(state: string) {
    replaceUrlParams(state)
}
export interface DateFilter {
    id: number,
    fromDate: string,
    toDate: string
}

export interface InvestorWarningsSearchState {
    query: string;
    pageNumber: number;
    totalPages: number;
    language: string;
    activeFilters: number[];
    dateFilter: DateFilter;
    sortAlphabetically: boolean;
    queryString: string;
}

const initialState: InvestorWarningsSearchState = {
    query: queryParams.get('query') || '',
    pageNumber: parseInt(queryParams.get('page') || '1', 10),
    totalPages: 10,
    activeFilters: queryParams.getAll('types')?.map(x => parseInt(x, 10)) || [],
    dateFilter: {
        id: parseInt(queryParams.get('from') || '',10) || -1,
        fromDate: queryParams.get('from') || '',
        toDate: queryParams.get('to') || '',
    },
    language: queryParams.get('language') ?? 'no',
    sortAlphabetically: queryParams.get('sortAlphabetically') === "true",
    queryString: ''

};

export const investorWarningsSlice = createSlice({
    name: 'investorWarningsState',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
            state.pageNumber = 1
            setQuerystring(getUrlParams(state));
        },
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
            setQuerystring(getUrlParams(state));
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
            setQuerystring(getUrlParams(state));
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
            setQuerystring(getUrlParams(state));
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
            setQuerystring(getUrlParams(state));
        },
        typeFilters: () => { },
        dateFilters: (state, action: PayloadAction<DateFilter>) => {             
            state.dateFilter = action.payload
            state.pageNumber = 1
            setQuerystring(getUrlParams(state))
        },
        sortResults: (state) => { 
            state.sortAlphabetically = !state.sortAlphabetically
            state.pageNumber = 1
            setQuerystring(getUrlParams(state))
         },
        checkTypeFilter: (state, action: PayloadAction<number>) => {
            if(state.activeFilters.indexOf(action.payload) > -1)
                state.activeFilters.splice(state.activeFilters.indexOf(action.payload),1)
            else
                state.activeFilters.push(action.payload)

            state.pageNumber = 1
            setQuerystring(getUrlParams(state))
        },
        resetFilters: (state) => {
            state.activeFilters = [];
            state.dateFilter = {
                id: -1,
                fromDate: '',
                toDate: ''
            }
            state.pageNumber = 1
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload
            setQuerystring(getUrlParams(state))
        }
    }
});

export const vregSearchReducer = investorWarningsSlice.reducer;
export const vregSearchActions = investorWarningsSlice.actions;
