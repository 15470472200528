import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxList from '../components/CheckboxList';
import FTYearPicker from '../components/ftYearPicker';
import Loader from '../components/loader';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';
import { DateFilter, investorWarningsSlice } from './state/investorWarningsReducer';
import { InvestorWarningRootState } from './state/store';
import { useGetMetadataQuery } from './state/investorWarningsApi';
import { SkeletonLoader } from '../components/SkeletonLoader';
import { isMobile } from '../util/ssrBase';

export const InvestorWarningsSearchFilters : FC = () => {
    const languageContext = useContext(LanguageContext);
    const state = useSelector((s: InvestorWarningRootState) => s.investorWarningState);

    const {data, isLoading, isFetching} = useGetMetadataQuery({language: state.language});
    const dispatch = useDispatch();

    const [showFilters, setShowFilters] = useState(!isMobile);
    const [showYears, setShowYears] = useState(!isMobile);

    useEffect(() => {
        dispatch(investorWarningsSlice.actions.setLanguage(languageContext.code));
    });
    
    if(data === undefined || isLoading || isFetching)
        return <SkeletonLoader type='header' />


    const { activeFilters, dateFilter } = state;
    if (data.length > 0) {
        return (
            <div className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400" >
                <button
                    title={translateLang('newsarchive.headers.filter', languageContext.code)}
                    type="button"
                    className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                    aria-expanded={showFilters}
                    aria-controls="filterselectors"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <span>{translateLang('newsarchive.headers.filter', languageContext.code)}</span>
                    <span className="material-icons" aria-hidden="true">
                        {showFilters ? 'close' : 'expand_more'}
                    </span>
                </button>

                {showFilters && (
                    <>
                        <div className='px-4'>
                            <CheckboxList
                                typefilters={data?.map((x) =>  ({
                                    checked: activeFilters?.indexOf(x.id) > -1,
                                    id: x.id,
                                    name: x.name,
                                    type: x.type,
                                }))}
                                onTypeFilterClick={(id) => dispatch(investorWarningsSlice.actions.checkTypeFilter(id))}
                                additionalClass="investor-warning-type-filter-class-name"
                            />
                            <button
                                title={translateLang('newsarchive.texts.yearFilterDescription', languageContext.code)}
                                type="button"
                                className="flex items-center justify-between w-full cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                                aria-expanded={showYears}
                                aria-controls="yearselector"
                                onClick={() => setShowYears(!showYears)}
                            >
                                <span>{translateLang('newsarchive.texts.yearFilterDescription', languageContext.code)}</span>
                                <span className="material-icons" aria-hidden="true">
                                    {showYears ? 'close' : 'expand_more'}
                                </span>
                            </button>
                            {showYears && (
                                <FTYearPicker
                                    language={languageContext.code}
                                    dateFilters={dateFilter}
                                    onDateSet={(dateFilters : DateFilter) => dispatch(investorWarningsSlice.actions.dateFilters(dateFilters))}
                                />
                            )}
                        </div>
                        <div className="flex justify-between p-4">
                            <button type="button" title={translateLang('newsarchive.buttons.resetFilter', languageContext.code)}
                                className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                onClick={() => dispatch(investorWarningsSlice.actions.resetFilters())}
                            >
                                <span className="underline group-hover:no-underline">{translateLang('newsarchive.buttons.resetFilter', languageContext.code)}</span>
                            </button>
                            <button
                                type="button" title={translateLang('newsarchive.buttons.closeFilter', languageContext.code)}
                                className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                onClick={() => setShowFilters(false)}
                            >
                                <span className="underline group-hover:no-underline">
                                    {translateLang('newsarchive.buttons.closeFilter', languageContext.code)}
                                </span>
                                <span className="ml-2 material-icons" aria-hidden="false">close</span>
                            </button>
                        </div>
                    </>
                )}

                {showFilters === false && (activeFilters?.length > 0 || dateFilter?.id !== -1) && (
                    <section className="border-t border-black-200 mb-4 mx-4 pt-2">
                        {activeFilters &&
                            <>
                                <p className="text-sm mb-1">
                                    {translateLang('newsarchive.texts.selectedNewsTypes', languageContext.code)}
                                </p>
                                <ul className="-mx-1 flex flex-wrap">
                                    {data.map(filter => {
                                        if(activeFilters.indexOf(filter.id) === -1)
                                            return null;

                                        return (<li className="p-1" key={filter.id}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    dispatch(investorWarningsSlice.actions.checkTypeFilter(filter.id));
                                                }}
                                                className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100">
                                                {filter.name}
                                            </button>
                                        </li>);
                                    }
                                    )}
                                </ul>
                            </>
                        }

                        {dateFilter?.id !== -1 && dateFilter?.id !== 1980 &&
                            <p className="text-sm mb-1">
                                {translateLang('newsarchive.texts.filteredForPeriod', languageContext.code, [dateFilter.id])}
                            </p>}
                        {dateFilter?.id === 1980 &&
                            // This is the ...and older selection
                            <p className="text-sm mb-1">
                                {translateLang('newsarchive.texts.filteredForPeriodAndOlder', languageContext.code, [dateFilter.toDate.substring(0, 4)])}
                            </p>}
                    </section>
                )}
            </div>
        );
    }

    return (<Loader isLoading languageCode={languageContext.code} />);


}
