/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import 'core-js/stable';
import 'moment/locale/nb';
import 'regenerator-runtime/runtime';
import './polyfills/element';

/* eslint-enable */
import * as analytics from './analytics';
import * as annualReportPage from './annualReport/annualReport';
import * as annualReportSubPage from './annualReport/annualReportSubPage';
import {TableComponent} from './blocks/tableBlock/index';
import * as calendarPage from './calendar/calendar';
import * as collapsibleTextBlock from './collapsibleTextBlock';
import { FrontPageSearch } from './frontpage/index';
import { InvestorWarnings } from './investorWarnings/investorWarnings';
import * as konsreg from './konsreg';
import * as konsregReports from './konsregReports';
import { Pillar2Archive } from './pillar2/pillar2';
import { SearchPageSearch } from './searchpage/index';
import { SearchResultsComponent } from './searchpage/searchResultsComponent';
import { tableStyles } from './tables';
import * as themeCategoryPage from './themeCategoryPage';
import * as topicCategoryPage from './topicCategoryPage';
import * as totop from './totop';
import * as tablesort from './tableSort';
import * as responsivetable from './responsivetable';

import { Popup } from './usersurvey/Popup';
import { scrollToPrevPosition } from './util/persistScroll';
import { window } from './util/ssrBase';
import { SubjectContainer } from './components/subjectSelector/subjectSelector2';
import { Header } from './components/menu/Header';

import { ArticlePage } from './pages/articleBase';
import { PageIndex } from './components/pageIndex/PageIndex';
import { ShortcutsList } from './components/shortcutsList/ShortcutsList';
import { SortablePageListing } from './components/sortablePageListing/SortablePageListing';
import { CollapsiblePanel } from './components/collapsiblePanel/CollapsiblePanel';
import { AddToCalendarButton } from './calendar/addToCalendarButton';
import { FeedbackComponent } from './components/feedback/FeedbackComponent';
import { CheckBoxList } from './components/newsletter/CheckBoxList';
import { NewsArchive } from './newsarchive/NewsArchive';
import { ProspectusArchive } from './prospectusRegistry/prospectusRegistry';
import { ImageBankImage } from './components/imageBankImage/ImageBankImage';
import { CookieBanner } from './components/cookieBanner/CookieBanner';
import { VregDetails } from './vreg/Details/VregDetails';
import { ThemesOverviewComponent } from './themesOverviewPage/themesOverviewComponent';
import { FinSecReport } from './components/forms/finSecReport';
import { VregSearchInputAndResults } from './vreg/Search/VregSearchInputAndResults';
import { VregSearchFilters } from './vreg/Search/VregSearchFilters';
import {PowerBiEmbed} from './blocks/powerBiEmbed/powerBiEmbed';
import { InspectionReportYearListing } from './blocks/InspectionReportYearListing/InspectionReportYearListing';
import { ReportByLicenceGroupsWrapper } from './vreg/Reports/ReportByLicenceGroups';
import { PredefinedReports } from './vreg/Reports/PredefinedReports';
import { VregReportsMain } from './vreg/Reports/VregReportsMain';
import { VregStandardReportWrapper } from './vreg/Reports/VregStandardReportView';

// Needed when we want to bundle React ourselves, also make sure to use SetLoadReact(false) in the ReactConfig.cs file
global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

// Components to expose, e.g.
global.Popup = Popup;
global.FrontPageSearch = FrontPageSearch;
global.SearchPageSearch = SearchPageSearch;
global.SearchResultsComponent = SearchResultsComponent;
global.SubjectSelector = SubjectContainer; // Fagomr�develger forside
global.Header = Header;
global.ArticlePage = ArticlePage;
global.PageIndex = PageIndex;
global.ShortcutsList = ShortcutsList;
global.SortablePageListing = SortablePageListing;
global.CollapsiblePanel = CollapsiblePanel;
global.AddToCalendarButton = AddToCalendarButton;
global.FeedbackComponent = FeedbackComponent;
global.NewsletterSupervisionCategoryGroup = CheckBoxList;
global.NewsArchive = NewsArchive;
global.InvestorWarnings = InvestorWarnings;
global.ProspectusArchive = ProspectusArchive;
global.Pillar2Archive = Pillar2Archive;
global.ImageBankImage = ImageBankImage;
global.CookieBanner = CookieBanner;
global.VregDetails = VregDetails;
global.ThemesOverview = ThemesOverviewComponent;
global.FinSecReport = FinSecReport;
global.VregSearchInputAndResults = VregSearchInputAndResults;
global.VregSearchFilters = VregSearchFilters;
global.PowerBiEmbed = PowerBiEmbed;
global.InspectionReportYearListing = InspectionReportYearListing;
global.ReportByLicenceGroupsWrapper = ReportByLicenceGroupsWrapper;
global.PredefinedReports = PredefinedReports;
global.VregReportsMain = VregReportsMain;
global.VregStandardReportWrapper = VregStandardReportWrapper;
global.TableComponent = TableComponent;

window.onload = () => {
    tableStyles();
    for (let i = 0; i < window.scripts?.length; i++) {
        switch (window.scripts[i]) {
            case topicCategoryPage.NAME:
                topicCategoryPage.init();
                break;
            case konsreg.NAME:
                konsreg.init();
                scrollToPrevPosition(konsreg.NAME);
                break;
            case konsregReports.NAME:
                konsregReports.init();
                break;
            case themeCategoryPage.NAME:
                themeCategoryPage.init();
                break;
            case collapsibleTextBlock.NAME:
                collapsibleTextBlock.init();
                break;
            case calendarPage.NAME:
                calendarPage.init();
                break;
            case annualReportPage.NAME:
                annualReportPage.init();
                break;
            case annualReportSubPage.NAME:
                annualReportSubPage.init();
                break;
            default:
                break;
        }
    }

    analytics.trackPageView();
    totop.init();

    tablesort.init();
    responsivetable.init();

};
