import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import SearchBox from '../components/search/searchBox';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';
import { investorWarningsSlice } from './state/investorWarningsReducer';

export const InvestorWarningsSearchBox : FC = () => {
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();

    return (
        <SearchBox
            initialQuery=''
            onSearch={(query: string) => dispatch(investorWarningsSlice.actions.setQuery(query))}
            searchOnMount
            buttonText={translateLang('globalsearch.buttons.search', languageContext.code)}
        />

    );
}
