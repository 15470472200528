import React, { useEffect, useState } from 'react';
import { translateLang } from '../../translate';
import { document, isServer } from '../../util/ssrBase';

interface PageIndexProps {
    language: string;
}

export const PageIndex: React.FC<PageIndexProps> = (props): JSX.Element | null => {
    const [activeId, setActiveId] = useState(String);
    const [toc, setToc] = useState<{ id: string; text: string }[]>([]);

    useEffect(() => {
        if (isServer) return () => {};

        const urlHash = decodeURI(window.location.hash);
        if (urlHash) {
            const h2Element = document.getElementById(urlHash.substring(1, urlHash.length));
            if (h2Element) {
                h2Element.scrollIntoView();
            }
        }

        let anchors = Array.from(
            document.querySelectorAll('h2:not(.feedbackComponent):not(.toc):not(.listing)')
        ) as HTMLElement[];
        let headingElements = Array.from(
            document.querySelectorAll('h2:not(.feedbackComponent):not(.toc):not(.listing)')
        );
        if (anchors.length === 0) {
            anchors = Array.from(
                document.querySelectorAll('h3:not(.feedbackComponent)')
            ) as HTMLElement[];
            headingElements = Array.from(document.querySelectorAll('h3'));
        }

        if (anchors.length === 0) {
            return () => {};
        }

        const tocArr: { id: string; text: string }[] = [];

        anchors.forEach((element) => {
            const id = element.innerText.replace(/\s/g, '');
            element.setAttribute('id', id); // Set ID to header in markup
            tocArr.push({
                id,
                text: element.innerText,
            });
        });

        setToc(tocArr);

        let isScrolling: ReturnType<typeof setTimeout>;
        const getClosestElementToTop = () => {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                headingElements.forEach((element) => {
                    const rect = element.getBoundingClientRect();
                    if (rect.top > -48 && rect.top < 48) {
                        setActiveId(element.id);
                    } else if (element.id === headingElements[0].id && rect.top > 49) {
                        setActiveId('');
                    }
                });
            }, 25);
        };

        document.addEventListener('scroll', getClosestElementToTop);

        return () => {
            document.removeEventListener('scroll', getClosestElementToTop);
        };
    }, []);

    return (
        <nav
            role="navigation"
            aria-label="Table of content"
            className="p-6 mb-6 bg-white-400 lg:sticky lg:top-6"
        >
            <h2 className="mb-4 text-base font-semibold toc">
                {translateLang('toc', props.language)}
            </h2>
            <ul className="flex flex-col space-y-3">
                {toc.map((item) => (
                    <li key={item.id}>
                        <a
                            href={`#${item.id}`}
                            aria-label={item.text}
                            className={`text-bluegreen-500 hover:underline no-underline decoration-1 underline-offset-4 ${
                                item.id === activeId && activeId !== undefined
                                    ? 'bg-bluegreen-100'
                                    : ''
                            } `}
                        >
                            {item.text}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
